<template>
  <div class="page">
    <van-nav-bar fixed :title="pageTitle" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon
          class-prefix="iconfont"
          name="liucheng"
          class="ico-feedback"
          @click="onClickRight"
        />
      </template>
    </van-nav-bar>
    <div class="alarm-detail">
      <div class="alarm-detail__tabel block-space-left-right">
        <div
          v-for="(item, idx) in tableConf.values"
          :key="idx"
          class="alarm-detail__tabel--rows"
        >
          <span class="left">{{ item.name }}</span>
          <span :class="['right', item.color && 'font-color']">
            {{ alarmRecordVO[item.key] }}
            <i v-if="item.unit">{{ item.unit }}</i>
          </span>
        </div>
      </div>
      <van-cell-group class="alarm-info">
        <van-cell title="处置状态" :value="alarmRecordVO.checkStatusName">
          <template #default>
            <span
              :style="{
                color: checkStatusColorSwitch(alarmRecordVO.checkStatus)
              }"
            >
              {{ alarmRecordVO.checkStatusName }}
            </span>
          </template>
        </van-cell>
        <van-cell title="所属设施" :value="alarmRecordVO.equipmentName" />
        <van-cell title="所属危险源" :value="alarmRecordVO.dangerName" />
        <van-cell title="值班电话">
          <template #default>
            <div class="blue-color">
              <a
                v-show="orgInfo.safetyDutyTel"
                :href="'tel:' + orgInfo.safetyDutyTel"
              >
                <span>{{ orgInfo.safetyDutyTel }}</span>
              </a>
            </div>
          </template>
        </van-cell>
        <van-cell title="HSE负责人">
          <template #default>
            <a
              v-show="orgInfo.safetyHeadTel"
              :href="'tel:' + orgInfo.safetyHeadTel"
            >
              <span class="blue-color">
                <span class="safety-head">{{ orgInfo.safetyHead }}</span>
                <span v-show="orgInfo.safetyHeadTel">{{
                  orgInfo.safetyHeadTel
                }}</span>
              </span>
            </a>
          </template>
        </van-cell>
      </van-cell-group>
      <div v-if="startAlarmDisposal || disposalFormDomWhiteList">
        <p class="interval"></p>
        <van-form ref="form">
          <van-field
            v-model="alarmDisposalForm.analysis"
            :clickable="startAlarmDisposal"
            :readonly="true"
            name="analysis"
            label="原因分析"
            placeholder="请输入内容"
            maxlength="200"
            required
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: '请输入原因分析',
                trigger: 'onChange'
              },
              {
                validator: analysisValidator,
                message: '原因分析长度不能超过200',
                trigger: 'onChange'
              }
            ]"
            @click="analysisClick()"
          >
            <template v-if="disposalFormDomWhiteList" #input>
              <p class="form-text">{{ alarmDisposalForm.analysis }}</p>
            </template>
          </van-field>
          <van-field
            v-model="alarmDisposalForm.measures"
            :clickable="startAlarmDisposal"
            :readonly="true"
            name="measures"
            label="处置措施"
            placeholder="请输入内容"
            maxlength="500"
            required
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: '请输入处置措施',
                trigger: 'onChange'
              },
              {
                validator: measuresValidator,
                message: '处置措施长度不能超过500',
                trigger: 'onChange'
              }
            ]"
            @click="measuresClick()"
          >
            <template v-if="disposalFormDomWhiteList" #input>
              <p class="form-text">{{ alarmDisposalForm.measures }}</p>
            </template>
          </van-field>
          <van-field
            v-model="alarmDisposalForm.completion"
            :clickable="startAlarmDisposal"
            :readonly="!startAlarmDisposal"
            name="completion"
            label="完成情况"
            placeholder="请输入内容"
            maxlength="200"
            required
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: '请输入完成情况',
                trigger: 'onChange'
              }
            ]"
          >
            <template v-if="disposalFormDomWhiteList" #input>
              <p class="form-text">{{ alarmDisposalForm.completion }}</p>
            </template>
          </van-field>
          <!--          <van-field-->
          <!--            :clickable="startAlarmDisposal"-->
          <!--            :readonly="!startAlarmDisposal"-->
          <!--            :is-link="startAlarmDisposal"-->
          <!--            :value="alarmDisposalForm.confirmerName"-->
          <!--            name="confirmerName"-->
          <!--            label="核验人"-->
          <!--            required-->
          <!--            input-align="right"-->
          <!--            error-message-align="right"-->
          <!--            placeholder="请选择"-->
          <!--            :rules="[-->
          <!--              {-->
          <!--                required: true,-->
          <!--                message: '请选择核验人',-->
          <!--                trigger: 'onChange'-->
          <!--              }-->
          <!--            ]"-->
          <!--            @click="checkPersonClick()"-->
          <!--          >-->
          <!--            <template #input v-if="disposalFormDomWhiteList">-->
          <!--              <p class="form-text">{{ alarmDisposalForm.confirmerName }}</p>-->
          <!--            </template>-->
          <!--          </van-field>-->
        </van-form>
        <select-person
          title="核验人"
          :visible="selectPersonVisible"
          :choose-value="choosePersonSelect"
          :show-department-name="true"
          :show-phone-number="false"
          :multiple="false"
          position="bottom"
          @close="selectPersonVisible = false"
          @confirm="onPersonConfirm"
        />
        <inputAndSelect
          :visible="inputSelectVisible"
          position="bottom"
          :title="inputAndSelectTitle"
          :input-value="inputValue"
          type="textarea"
          :autosize="autosize"
          :rows="1"
          :select-data="textList"
          @close="inputSelectVisible = false"
          @confirm="inputAndSelectConfirm"
        />
      </div>
      <div v-if="startVerification" class="alarm-verification">
        <p class="interval"></p>
        <van-form ref="verification-form">
          <van-field
            name="operation"
            label="核验意见"
            required
            input-align="right"
            error-message-align="right"
            :rules="[{ required: true, message: '请选择核验意见' }]"
          >
            <template #input>
              <van-radio-group
                v-model="verificationInfo.operation"
                direction="horizontal"
              >
                <van-radio name="4">确认</van-radio>
                <van-radio name="5">驳回</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
            v-model="verificationInfo.remark"
            name="remark"
            label="备注"
            placeholder="请输入内容"
            maxlength="500"
            rows="2"
            autosize
            type="textarea"
            show-word-limit
            input-align="right"
          />
        </van-form>
      </div>
    </div>
    <bottom-fixed-box v-if="showButton !== 'false'">
      <template v-if="alarmRecordVO.checkStatus === '1' && btnAuth.feedback">
        <van-button class="btn-default" @click="ignoreAlarm()"
          >忽略报警</van-button
        >
        <van-button class="btn-primary" @click="confirmAlarm()"
          >确认报警</van-button
        >
      </template>
      <template
        v-if="
          ['2', '5'].includes(alarmRecordVO.checkStatus) && btnAuth.feedback
        "
      >
        <van-button
          v-if="!startAlarmDisposal"
          class="btn-primary"
          @click="alarmDisposal()"
          >报警处置</van-button
        >
        <van-button
          v-if="startAlarmDisposal"
          class="btn-primary"
          @click="alarmDisposalSubmit()"
          >提交</van-button
        >
      </template>
      <!--      <template v-if="alarmRecordVO.checkStatus === '3' && btnAuthority">-->
      <template
        v-if="['3'].includes(alarmRecordVO.checkStatus) && btnAuth.approve"
      >
        <!--        <van-button-->
        <!--          v-if="btnAuth.feedback"-->
        <!--          class="btn-default"-->
        <!--          @click="checkRejected()"-->
        <!--          >驳回</van-button-->
        <!--        >-->
        <!--        <van-button-->
        <!--          v-if="btnAuth.feedback"-->
        <!--          class="btn-primary"-->
        <!--          @click="checkConfirm()"-->
        <!--          >确认</van-button-->
        <!--        >-->
        <van-button
          v-if="!startVerification"
          class="btn-primary"
          @click="startVerification = true"
          >核验</van-button
        >
        <van-button
          v-if="startVerification"
          class="btn-primary"
          @click="onVerification"
          >提交</van-button
        >
      </template>
    </bottom-fixed-box>
  </div>
</template>

<script>
import alarm from "@/api/alarm";
// import EmptyData from "@/components/emptyData";
import messageApi from "@/api/message";
import { mapActions, mapState, mapMutations } from "vuex";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import SelectPerson from "@/components/SelectPerson";
import inputAndSelect from "@/components/inputAndSelect";
import { verificationAlarmById } from "@/api/warning";
import { operateMessage } from "@/utils";

export default {
  name: "AlarmDetail",
  components: {
    // EmptyData,
    BottomFixedBox,
    SelectPerson,
    inputAndSelect
  },
  data() {
    return {
      showButton: "",
      active: "alarm-info",
      alarmRecordVO: {},
      orgInfo: {},
      logList: [],
      tableConf: {
        key: "alarmRecordVO",
        values: [
          {
            key: "indicatorName",
            name: "点位名称"
          },
          {
            key: "indicatorNum",
            name: "位号"
          },
          {
            key: "indicatorValue",
            name: "监测值"
          },
          {
            key: "alarmThreshold",
            name: "报警阈值"
          },
          {
            name: "报警时间",
            key: "firstAlarmTime"
          },
          {
            name: "报警时长",
            key: "alarmTime"
          },
          {
            name: "监测项",
            key: "indicatorTypeName"
          },
          {
            name: "监测因子",
            key: "indicatorFactor"
          },
          {
            name: "报警类型",
            key: "alarmStatusName",
            color: "#FD687D"
          },
          {
            name: "报警状态",
            key: "handleStatusName",
            color: "#FD687D"
          }
        ]
      },
      startAlarmDisposal: false,
      alarmDisposalForm: {
        alarmRecordId: "", // 报警记录id
        analysis: "", // 原因分析
        measures: "", // 处置措施
        completion: "", // 完成情况
        confirmerId: "", // 核验人
        confirmerName: ""
      },
      startVerification: false,
      verificationInfo: {
        operation: "",
        remark: ""
      },
      selectPersonVisible: false,
      choosePersonSelect: [],
      inputSelectVisible: false,
      inputAndSelectTitle: "原因分析",
      inputValue: "",
      autosize: {
        // minHeight: 26,
        maxHeight: 76
      },
      textList: []
    };
  },
  mounted() {
    this.initPage();
  },
  created() {
    this.showButton = String(this.$route.query.showButton);
    this.readMessage();
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth,
      userInfo: state => state.login.userInfo,
      needRefreshList: state => state.alarm.needRefreshList
    }),
    btnAuthority() {
      return this.userInfo.id === this.alarmDisposalForm.confirmerId;
    },
    disposalFormDomWhiteList() {
      const list = ["3", "4"];
      return list.includes(this.alarmRecordVO.checkStatus);
    },
    alarmId() {
      return this.$route.params.id;
    },
    pageTitle() {
      if (!this.alarmRecordVO.indicatorName) {
        return `详情`;
      }
      return (
        this.alarmRecordVO.indicatorName +
        "(" +
        this.alarmRecordVO.indicatorNum +
        ")"
      );
    },
    checkStatusColorSwitch() {
      return checkStatus => {
        const obj = {
          0: "#9b9b9b",
          1: "#5a96e0",
          2: "#F68446",
          3: "#7E74E5",
          4: "#8EB433"
        };
        return obj[checkStatus];
      };
    }
  },
  methods: {
    ...mapMutations({
      setNeedRefreshList: "setNeedRefreshList"
    }),
    ...mapActions(["getMessageCountNoticesAction"]),
    // 核验
    async onVerification() {
      try {
        const validate = await this.$refs["verification-form"].validate();
        if (validate) return;
        const r = await verificationAlarmById(
          this.alarmId,
          this.verificationInfo
        );
        operateMessage(r, "核检");
        if (r) {
          this.startVerification = false;
          this.initPage();
        }
      } catch (e) {
        console.log(e);
      }
    },
    analysisValidator(val) {
      return val.length <= 200;
    },
    measuresValidator(val) {
      return val.length <= 500;
    },
    inputAndSelectConfirm(val) {
      if (this.inputAndSelectTitle === "原因分析") {
        this.alarmDisposalForm.analysis = val;
        return;
      }
      this.alarmDisposalForm.measures = val;
    },
    async analysisClick() {
      if (this.startAlarmDisposal) {
        let params = {
          indicatorId: this.alarmRecordVO.indicatorId,
          alarmStatus: this.alarmRecordVO.alarmStatus
        };
        let res = await alarm.getAlarmGuideCause(params).catch(_ => {});
        this.textList = res || [];
        this.inputAndSelectTitle = "原因分析";
        this.inputValue = this.alarmDisposalForm.analysis;
        this.inputSelectVisible = true;
      }
    },
    async measuresClick() {
      if (this.startAlarmDisposal) {
        let params = {
          indicatorId: this.alarmRecordVO.indicatorId,
          alarmStatus: this.alarmRecordVO.alarmStatus
        };
        let res = await alarm.getAlarmGuideReact(params).catch(_ => {});
        this.textList = res || [];
        this.inputAndSelectTitle = "处置措施";
        this.inputValue = this.alarmDisposalForm.measures;
        this.inputSelectVisible = true;
      }
    },
    async initPage() {
      await this.getAlarmInfoById(this.alarmId);
      this.disposalFormDomWhiteList && this.getDisposalFormData();
      if ("activeName" in this.$route.params) {
        this.active = this.$route.params.activeName;
      }
    },
    getDisposalFormData() {
      alarm.getDisposeAlarm(this.alarmId).then(res => {
        if (typeof res === "object") {
          this.alarmDisposalForm = res;
          this.choosePersonSelect = [
            {
              label: res.confirmerName || "",
              value: res.confirmerId || ""
            }
          ];
        }
      });
      // .catch(err => {
      //   this.$toast.fail("请求失败,请重试");
      // });
    },
    onPersonConfirm(ids, list) {
      this.alarmDisposalForm.confirmerName = list[0] ? list[0].label || "" : "";
      this.alarmDisposalForm.confirmerId = list[0] ? list[0].value || "" : "";
      this.choosePersonSelect = list;
    },
    ignoreAlarm() {
      this.$dialog
        .confirm({ message: "确认忽略本次报警信息？忽略后无法记录处置信息" })
        .then(() => {
          alarm
            .ignoreAlarm(this.alarmId)
            .then(res => {
              this.$toast.success("操作成功");
            })
            // .catch(error => {
            //   this.$toast.fail("请求失败,请重试");
            // })
            .finally(() => {
              this.setNeedRefreshList({ value: true });
              this.$router.back();
            });
        });
    },
    confirmAlarm() {
      this.$dialog.confirm({ message: "确认报警信息？" }).then(() => {
        alarm
          .confirmAlarm(this.alarmId)
          .then(res => {
            this.$toast.success("操作成功");
          })
          // .catch(error => {
          //   this.$toast.fail("请求失败,请重试");
          // })
          .finally(() => {
            this.setNeedRefreshList({ value: true });
            this.$router.back();
          });
      });
    },
    async alarmDisposal() {
      await this.getDisposalFormData();
      if (!this.alarmDisposalForm.id) {
        this.alarmDisposalForm = {
          alarmRecordId: this.alarmId, // 报警记录id
          analysis: "", // 原因分析
          measures: "", // 处置措施
          completion: "", // 完成情况
          confirmerId: "", // 核验人
          confirmerName: ""
        };
      }
      this.startAlarmDisposal = true;
    },
    alarmDisposalSubmit() {
      this.$refs.form.validate().then(() => {
        this.$dialog
          .confirm({ message: "确认提交报警处置信息？" })
          .then(async () => {
            try {
              await alarm.disposeAlarm(this.alarmDisposalForm);
              this.setNeedRefreshList({ value: true });
              this.$router.back();
            } catch (e) {
              // this.$toast.fail("请求失败,请重试");
            }
          });
      });
    },
    checkRejected() {
      this.$dialog.confirm({ message: "确认驳回处置信息？" }).then(async () => {
        try {
          await alarm.alarmReject(this.alarmId);
          this.setNeedRefreshList({ value: true });
          this.$router.back();
        } catch (e) {
          // this.$toast.fail("请求失败,请重试");
        }
      });
    },
    checkConfirm() {
      this.$dialog.confirm({ message: "确认处置信息？" }).then(async () => {
        try {
          await alarm.alarmCheck(this.alarmId);
          this.setNeedRefreshList({ value: true });
          this.$router.back();
        } catch (e) {
          // this.$toast.fail("请求失败,请重试");
        }
      });
    },
    checkPersonClick() {
      if (this.startAlarmDisposal) {
        this.selectPersonVisible = true;
      }
    },
    async getAlarmInfoById(id) {
      await alarm.getAlarmDetail(id).then(data => {
        const { alarmRecordVO, orgInfo } = data;
        this.alarmRecordVO = alarmRecordVO;
        this.orgInfo = orgInfo;
      });
    },
    onClickLeft() {
      history.go(-1);
    },
    onClickRight() {
      this.$router.push({
        name: "AlarmDisposalLog",
        params: { id: this.alarmId }
      });
    },
    readMessage() {
      if (this.$route.query.messageId) {
        messageApi
          .updateBasePageNotices({
            ids: this.$route.query.messageId
          })
          .then(() => {
            this.getMessageCountNoticesAction();
          });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page {
  text-align: left;
  height: 100vh;
  background: #fff;
}
.alarm-detail {
  overflow: auto;
  padding: 58px 0 0;
  // height: calc(100vh - 58px);
  &__tabel {
    display: flex;
    flex-wrap: wrap;
    height: 265px;
    border-radius: 6px;
    border: 1px solid rgba(231, 232, 234, 1);
    font-size: 14px;
    // margin-top: 17px;
    // margin-bottom: 20px;
    margin: 27px 16px 17px;
    overflow: hidden;
  }
  &__tabel--rows {
    width: 170px;
    // height: 52px;
    display: flex;
    border: 1px solid rgba(231, 232, 234, 1);
    border-right: none;
    border-bottom: none;
    box-sizing: border-box;
    &:nth-child(2n + 1) {
      border-left: none;
    }
    &:nth-child(1),
    &:nth-child(2) {
      border-top: none;
    }
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 67.5px;
      height: inherit;
      text-align: left;
      padding: 10px 5px;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 18px;
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-wrap: wrap;
      width: 103px;
      height: inherit;
      border-left: 1px solid rgba(231, 232, 234, 1);
      font-size: 13px;
      font-weight: 400;
      color: #3b4664;
      word-break: break-all;
      word-wrap: break-word;
      padding: 9px;
      box-sizing: border-box;
      line-height: 18px;
    }
    .font-color {
      color: #fd687d;
    }
  }
}

.alarm-detail ::v-deep {
  .van-tab--active {
    color: #1890ff;
  }
  .van-step__icon--active,
  .van-step__title--active {
    color: #1890ff;
  }
  .van-step--vertical .van-step__circle-container {
    width: 8px;
    height: 8px;
    background: rgba(24, 144, 255, 1);
    box-shadow: 0px 0px 10px rgba(24, 144, 255, 1);
    border-radius: 50%;
  }
  .van-step__circle {
    display: none;
  }
}

.ico-feedback {
  font-size: 24px;
}
.alarm-info {
  i.ico-call {
    color: #fd687d;
  }
  .blue-color {
    color: #1676ff;
  }
  .safety-head {
    margin: 0 4px 0 0;
  }
}
.interval {
  height: 8px;
  background: #eceef2;
}
.form-text {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #8c8f97;
}
</style>
